<template>
  <div class="content">
    <div>
      <span class="text-annotationColor text-sm font-SourceSansPro"> {{ $t('reportsFilter.filterType') }}</span>
      <el-tooltip :open-delay="500" :visible-arrow="false" :offset="2" placement="right" effect="customized">
        <template #content>
          <div class="custom-tooltip">
            <p>
              <span class="title">
                {{ $t('reportsFilter.hint.in') }}:
              </span>
              {{ $t('reportsFilter.hint.in.text') }}
            </p>
            <p>
              <span class="title">
                {{ $t('reportsFilter.hint.out') }}:
              </span>
              {{ $t('reportsFilter.hint.out.text') }}
            </p>
            <a class="font-bold underline" href="https://wiki.skif.pro/reports" target="_blank">wiki.skif.pro</a>
          </div>
        </template>
        <span class="skif-info-icon m-0 ml-2">
          <InfoIcon class="mb-1" :color="'#3A7CCA'" />
        </span>
      </el-tooltip>
    </div>
    <div class="flex flex-col">
      <el-select class="w-2/3" :class="{ 'is-error': errors.selectedFilterType }" v-model="selectedFilterType" @change="updateFilterType">
        <el-option
          v-for="item in filter_geozones_type"
          :key="item.label"
          :label="item.value"
          :value="item.key" />
      </el-select>
      <span v-if="errors.selectedFilterType" class="error-message">{{ errors.selectedFilterType }}</span>
    </div>
    <div class="flex flex-col" v-if="selectedFilterType">
      <el-select class="w-2/3" :class="{ 'is-error': errors.selectedGeozones }" clearable filterable multiple v-model="selectedGeozones" @change="updateGeozones">
        <el-option v-for="geozone in geozones_list" :key="geozone.id" :label="geozone.name" :value="geozone.id" />
      </el-select>
      <span v-if="errors.selectedGeozones" class="error-message">{{ errors.selectedGeozones }}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'FilterByGeozone',
  props: {
    existingGeozonesFilter: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    InfoIcon: () => import('@/assets/svg-icons/info.svg')
  },
  computed: {
    ...mapGetters('dictionary', ['filter_geozones_type']),
    ...mapGetters('geozones', ['geozones_list'])
  },

  watch: {
    selectedFilterType(newVal) {
      this.updateFilterType(newVal)
    }
  },
  data() {
    return {
      filter: {
        filterType: {},
        geozones: []
      },
      selectedGeozones: [],
      selectedFilterType: '',
      errors: {
        selectedFilterType: null,
        selectedGeozones: null
      }
    }
  },
  methods: {
    validate() {
      let isValid = true
      this.errors.selectedFilterType = null
      this.errors.selectedGeozones = null

      if (!this.selectedFilterType) {
        this.errors.selectedFilterType = this.$t('events.validation.required')
        isValid = false
      }

      if (!this.selectedGeozones.length) {
        this.errors.selectedGeozones = this.$t('events.validation.required')
        isValid = false
      }

      this.$emit('validationResult', isValid)
    },
    updateGeozones(selectedIds) {
      this.filter.geozones = selectedIds.map(id => ({ id: id }))
      this.$emit('geozonesFilter', this.filter)
    },
    updateFilterType(selectedType) {
      const selectedObject = this.filter_geozones_type.find(item => item.key === selectedType)
      this.filter.filterType = selectedObject || {}
      this.$emit('geozonesFilter', this.filter)
    }
  },
  mounted() {
    if (this.existingGeozonesFilter && this.existingGeozonesFilter.filter_geozones_type) {
      this.selectedFilterType = this.existingGeozonesFilter.filter_geozones_type.key
      this.selectedGeozones = this.existingGeozonesFilter.geozones.map(geozone => {
        return geozone.id
      })

      this.updateGeozones(this.selectedGeozones)
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: column;
}
.custom-tooltip {
  width: 250px;
  background-color: #dbebff;
  color: #2767b6;
  padding: 6px 8px;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  font-weight: 400;
  font-size: 14px;
  white-space: normal;
  box-shadow: none;
  border: none;
  text-align: left;
  .title {
    font-weight: bolder;
  }
}
.error-message {
  color: #f56c6c;
  font-size: 12px;
  font-weight: 400;
}
.is-error {
  border: 1px solid #f56c6c;
  border-radius: 4px;
}
</style>
